<template>
  <div class="mt-3 text-grey1 mx-auto px-5 py-8 sm:w-80 lg:max-w-2xl">
    <div class="font-semibold">
      {{ colleagues.length }} colleagues are already Meetric users:
    </div>
    <template>
      <div class="my-3">
        <template v-for="colleague in colleagues">
          <div :key="colleague">
            <div>
              <span :title="colleague" data-recording-sensitive>
                {{ colleague }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>

    <div>
      <a-link
        segmentName="Learn team pro"
        href="https://meetric.notion.site/Meetric-Pro-Team-33a00e7b38ee4e0b9829a5b4b9577da2"
        >Learn about Team upgrade</a-link
      >
    </div>
  </div>
</template>

<script>
import ALink from '../../components/UI/ALink.vue';

export default {
  components: { ALink },
  name: 'Company',
  data() {
    return {
      colleagues: [],
    };
  },
  mounted() {
    this.getColleagues();
  },
  methods: {
    getColleagues() {
      this.colleagues = this.$store.getters['colleagues'].colleagues;
    },
  },
};
</script>

